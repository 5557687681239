@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Tilt+Neon&display=swap');

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    * {
        font-family: 'Tilt Neon', cursive;
    }
}

// // Scrollbar Styles

// ::-webkit-scrollbar {
//     width: 10px;
// }

// // Track
// ::-webkit-scrollbar-track {
//     background: #e0e0e0;
// }

// // Handled
// ::-webkit-scrollbar-thumb {
//     background: #888;
// }

// // Handle on Hover
// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

button.active {
    background-color: #3e4644 !important;
    border: 1px solid #3e4644 !important;
    cursor: default !important;
}

.board {
    background-image: url('./static/images/game_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    padding: 1.5rem;
    position: relative;
    min-height: 86.2vh;
}

// ********* FLIP CARD STYLES ************* //
.CardContainer {
    perspective: 1000px;
    margin-left: auto;
    margin-right: auto;

    .PokeCard {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        .front,
        .back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            /* Safari */
            backface-visibility: hidden;
        }

        /* Style the back side */
        .back {
            transform: rotateY(180deg);
        }

    }
}

.found {
    transform: rotateY(180deg);
}

.beating {
    animation: beat 1.2s infinite alternate;
    transform-origin: center;
}

.PokeCard.active {
    transform: rotateY(180deg);
}

.end-game-view {
    position: absolute;
    background-color: rgba(241, 129, 34, 0.4);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.none { 
    display: none !important;
}

.winner-trophy {
    animation: trophyShake 0.4s infinite alternate;
    color: #ffd700 !important;
    font-size: 2rem !important;
}

.block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    min-height: 300px;
}

.center-content {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.display-card {
    animation: flipping 5s infinite linear;
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    // min-height: 200px;
}

.display-card:hover {
    animation-play-state: paused;
    transform: rotateY(0deg) !important;
    cursor: pointer;
}

.fav-btn {
    color: #FFD700 !important;
    z-index: 1000;
    position: absolute !important;
}

.cardElement:hover button {
    display: block !important;
}

.list-items li {
    padding: 0 !important;
    margin: 0 !important;
}

.list-items:hover > div {
    left: 0;
}

.list-items:hover button {
    display: block !important;
}

.hamburger-menu {
    // background-color: rgba(51,51,51,0.9);
    // background-color: RGBA(156,174,169,0.7);
    background-color: RGB(127,128,140, 0.7);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    padding-top: 1rem;
    transition: all 0.3s ease-out;
    left: 100%;
}

.hamburger-menu.open {
    left: 0;
}

.alert-content {
    position: absolute;
    top: 0;
    right: 10px;
    width: 200px;
    z-index: 1000;
}

.hoverEff {
    // background-color: #9b9b9b;
    background-color: rgba(242, 242, 242, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    border-radius: 1rem;
    display: none;
    transition: all 1s ease-out;
    cursor: pointer;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #12171d;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #393e44;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555a61;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background-color: #71767e;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: #12171d;
  }

    // Handle on Hover
    ::-webkit-scrollbar-thumb:hover {
        background: #25282c;
    }

@keyframes beat {
    to { transform: scale(1.1); }
}

@keyframes trophyShake {
    from { transform: rotateZ(-20deg); }
    to { transform: rotateZ(20deg); }
}

@keyframes flipping {
    from { transform: rotateY(0deg);}
    to { transform: rotateY(360deg);}
}